import { createApp } from 'vue'

import App from '@/App.vue'
import { registerPlugins } from '@core/utils/plugins'
// eslint-disable-next-line import/extensions
import i18n from '@/plugins/i18n'

// Styles
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

// Create vue app
const app = createApp(App)

// Register plugins
registerPlugins(app)
app.use(i18n)

// Mount vue app
app.mount('#app')
