<script setup lang="ts">
import { provide, ref } from 'vue'

const show = ref(false)
const message = ref('')
const color = ref('')

provide('snackbar', {
  show(options) {
    message.value = options.message
    color.value = options.color
    show.value = true
  },
  hide() {
    show.value = false
  },
})
</script>

<template>
  <slot />
  <VSnackbar
    v-model="show"
    :color="color"
    location="top end"
    variant="flat"
    timeout="4000"
  >
    {{ message }}
    <template #actions>
      <VBtn
        variant="text"
        @click="show = false"
      >
        <i class="ri-close-line" />
      </VBtn>
    </template>
  </VSnackbar>
</template>
